import {
    FORMATS,
    intlDateFormat,
    intlDatePlaceholder
} from '@naturehouse/nh-essentials/lib/dates/intlDateTimeFormatter';

export default class CalendarInput extends HTMLElement {
    static observedAttributes = ['disabled', 'required'];

    #value = '';

    readonly #textElement: HTMLSpanElement = document.createElement('span');

    readonly #hiddenInput: HTMLInputElement = document.createElement('input');

    get disabled(): boolean {
        return this.hasAttribute('disabled');
    }

    set disabled(value: boolean) {
        this.toggleAttribute('disabled', value);
    }

    get required(): boolean {
        return this.hasAttribute('required');
    }

    set required(value: boolean) {
        this.toggleAttribute('required', value);
    }

    constructor() {
        super();
        this.value = this.getAttribute('value') || '';

        this.#updateDisplay();

        this.#hiddenInput.type = 'hidden';
        this.#hiddenInput.value = this.#value;
        this.#hiddenInput.name = this.getAttribute('name') || '';

        this.appendChild(this.#hiddenInput);
        this.appendChild(this.#textElement);
    }

    set value(value: string) {
        if (value !== '' && !value.match(/[\d]{4}-[\d]{2}-[\d]{2}/)) {
            return;
        }

        this.#value = value;
        this.#hiddenInput.value = this.#value;
        this.#updateDisplay();
    }

    get value(): string {
        return this.#value;
    }

    get #displayValue(): string {
        return this.#value === '' ? '' : intlDateFormat(this.#value, FORMATS.SHORT);
    }

    public dispatchEvent(event: Event): boolean {
        this.#hiddenInput.dispatchEvent(event);
        return true;
    }

    protected attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
        this.#hiddenInput.toggleAttribute(name, newValue !== null);
    }

    #updateDisplay(): void {
        if (!this.#displayValue) {
            this.#textElement.innerHTML = intlDatePlaceholder();
            this.#textElement.classList.add('nh-date__placeholder');
            return;
        }

        this.#textElement.innerHTML = this.#displayValue;
        this.#textElement.classList.remove('nh-date__placeholder');
    }
}

if (!customElements.get('calendar-input')) {
    customElements.define('calendar-input', CalendarInput);
}
