import '../date-from-to/DateFromTo';
import type { DateFromToProps } from '../date-from-to/DateFromTo';
import template from './TravelCompanyInput.html.njk';
import './TravelCompanyInput.pcss';

const fields: string[] = ['adults', 'children', 'babies', 'pets'];

export type TravelCompanyProps = {
    disabled?: boolean;
    adults?: string;
    children?: string;
    babies?: string;
    pets?: string;
    placeholder?: string;
    translations?: Record<string, string>;
};

export default class TravelCompanyInput extends HTMLElement {
    #isDisabled: boolean = this.hasAttribute('aria-disabled');

    get isDisabled(): boolean {
        return this.#isDisabled;
    }

    set isDisabled(value: boolean) {
        this.#isDisabled = value;
        this.toggleAttribute('aria-disabled', value);
        this.toggleAttribute('readonly', value);
    }

    static get observedAttributes(): string[] {
        return [...fields, 'placeholder', 'disabled'];
    }

    get validity(): ValidityState {
        return {
            badInput: false,
            customError: false,
            patternMismatch: false,
            rangeOverflow: false,
            rangeUnderflow: false,
            stepMismatch: false,
            tooLong: false,
            tooShort: false,
            typeMismatch: false,
            valueMissing: false,
            valid: true
        };
    }

    get value(): string {
        const adults = Number(this.#adults);
        const children = Number(this.#children);
        const babies = Number(this.#babies);
        const pets = Number(this.#pets);

        if (!adults && !children && !babies && !pets) {
            return '';
        }

        return JSON.stringify({
            adults,
            children,
            babies,
            pets
        });
    }

    set value(value) {
        if (value === '') {
            this.removeAttribute('adults');
            this.removeAttribute('children');
            this.removeAttribute('babies');
            this.removeAttribute('pets');
            return;
        }

        const { adults, children, babies, pets } = JSON.parse(value);

        this.setAttribute('adults', adults || '0');
        this.setAttribute('children', children || '0');
        this.setAttribute('babies', babies || '0');
        this.setAttribute('pets', pets || '0');
    }

    constructor(props?: DateFromToProps) {
        super();

        this.#init(props);
        this.addEventListener('clear', this.#onClear);
    }

    protected attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
        if (oldValue === newValue) {
            return;
        }

        if (name === 'disabled') {
            this.isDisabled = this.hasAttribute('disabled');
        }

        this.#render();

        if (fields.includes(name)) {
            this.#updateInputs();
            this.dispatchEvent(new Event('change'));
        }
    }

    protected connectedCallback(): void {
        this.#isDisabled = this.hasAttribute('aria-disabled');
        this.#render();
    }

    #onClear(event: Event): void {
        if (this.#isDisabled) {
            event.preventDefault();
            return;
        }

        this.value = '';
        this.focus();
        this.dispatchEvent(new CustomEvent('cleared'));
    }

    #init(props?: TravelCompanyProps): void {
        const adults = props?.adults || this.#adults;
        const children = props?.children || this.#children;
        const babies = props?.babies || this.#babies;
        const pets = props?.pets || this.#pets;
        const placeholder = props?.placeholder || this.#placeholder;
        const translations = props?.translations || this.#translations;

        this.setAttribute('adults', adults);
        this.setAttribute('children', children);
        this.setAttribute('babies', babies);
        this.setAttribute('pets', pets);
        this.setAttribute('placeholder', placeholder);
        this.setAttribute('translations', JSON.stringify(translations));

        this.setAttribute('tabindex', this.getAttribute('tabindex') || '0');

        const shadow = this.attachShadow({ mode: 'open' });
        shadow.innerHTML = template.render({
            value: this.#renderValue(),
            placeholder: this.#placeholder
        });
    }

    get #adults(): string {
        return this.getAttribute('adults') || '0';
    }

    get #children(): string {
        return this.getAttribute('children') || '0';
    }

    get #babies(): string {
        return this.getAttribute('babies') || '0';
    }

    get #pets(): string {
        return this.getAttribute('pets') || '0';
    }

    get #placeholder(): string {
        return this.getAttribute('placeholder') || '';
    }

    get #translations(): Record<string, string> {
        return JSON.parse(this.getAttribute('translations') || '{}');
    }

    #render(): void {
        if (!this.shadowRoot) {
            return;
        }

        const placeholder = this.shadowRoot.querySelector('[part="placeholder"]') as HTMLElement;
        const container = this.shadowRoot.querySelector('[part="container"]') as HTMLElement;

        const hasValue = this.value !== '';
        placeholder.toggleAttribute('hidden', hasValue);
        container.toggleAttribute('hidden', !hasValue);
        container.innerText = this.#renderValue();
    }

    #getValues(): Map<string, number> {
        const values = new Map();
        values.set('adults', Number(this.#adults));
        values.set('children', Number(this.#children));
        values.set('babies', Number(this.#babies));
        values.set('pets', Number(this.#pets));

        return values;
    }

    #renderValue(): string {
        if (this.value === '') {
            return '';
        }

        const values = this.#getValues();
        const texts = this.#getTexts(values);

        return fields
            .map((fieldName) => {
                const value = values.get(fieldName);
                if (value === 0) {
                    return false;
                }
                return `${value} ${texts.get(fieldName)}`;
            })
            .filter((value) => value)
            .join(', ');
    }

    #updateInputs(): void {
        fields.forEach((fieldName) => {
            const input = this.querySelector(
                `[data-input="${fieldName}"]`
            ) as HTMLInputElement | null;

            if (input === null) {
                return;
            }

            input.value = this.getAttribute(fieldName) || '0';
        });
    }

    #getText(name: string, singularName: string, values: Map<string, number>): string {
        return Number(values.get(name)) > 1
            ? this.#translations[name] || name
            : this.#translations[singularName] || singularName;
    }

    #getTexts(values: Map<string, number>): Map<string, string> {
        const texts = new Map();

        texts.set('adults', this.#getText('adults', 'adult', values));
        texts.set('children', this.#getText('children', 'child', values));
        texts.set('babies', this.#getText('babies', 'baby', values));
        texts.set('pets', this.#getText('pets', 'pet', values));

        return texts;
    }
}

if (!window.customElements.get('nh-travel-company')) {
    window.customElements.define('nh-travel-company', TravelCompanyInput);
}
